// @flow

import { createSlice } from '@reduxjs/toolkit'

import { classificationLegend, changeLegend } from '../config'

const initialState = {
  classification: classificationLegend,
  change: changeLegend
}

function setLegend (state, action) {
  const { id, legend } = action

  state[id] = legend
}

const legendDuck = createSlice({
  name: 'legend',
  initialState,
  reducers: {
    setLegend
  }
})

export default legendDuck
