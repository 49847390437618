// @flow

import React from 'react'

import Provider from './Provider'
import theme from './theme'
import NavigationContainer from './navigation/NavigationContainer'

import reactGA from './utils/reactGA'
import art from './components/art'
import './config'

reactGA.pageview('/')

console.log(art, "font-family:monospace; color:#005da6;")

function App() {

  return (
    <Provider theme={theme}>
      <NavigationContainer />
    </Provider>
  );
}

export default App;
