// @flow

import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

import mapDuck from '../redux/mapDuck'
import { getRiverFromId, getSelectedItem } from '../redux/firestoreSelector'
import fitBounds from '../utils/fitBounds'

// TODO: This logic is left over from river-ice-web and should probably
// be removed.
const mapEpic = (action$, state$) =>
  action$.pipe(
    // tap(action => console.log(action)),
    ofType(
      { type: '@@reduxFirestore/LISTENER_RESPONSE', meta: {}, payload: {} },
      mapDuck.actions.changeRiver
    ),
    mergeMap(action => {
      // console.log(action.type)
      const { payload } = action

      const state = state$.value
      const riverId = payload.slice(1)

      const river = getRiverFromId(state, { riverId })

      const item = getSelectedItem(state)
      const { bounds: riverBounds } = river

      // riverBounds[0] !== 0 for edge case when debugging firestore statr
      if (riverBounds && item && item.bbox && riverBounds[0] !== 0) {
        const { bbox: itemBounds } = item

        const { longitude, latitude, zoom } = fitBounds({
          bounds: itemBounds,
          divisor: 1
        })
        const { zoom: riverZoom } = fitBounds({
          bounds: riverBounds,
          divisor: 1
        })
        const multiplier = 1.01
        const maxBounds = [
          riverBounds[0] * multiplier,
          riverBounds[1] * (1 / multiplier),
          riverBounds[2] * (1 / multiplier),
          riverBounds[3] * multiplier
        ]

        return [
          mapDuck.actions.changeViewport({ latitude, longitude, zoom }),
          mapDuck.actions.setMaxBounds(maxBounds),
          mapDuck.actions.setMinZoom(riverZoom - 1.5)
        ]
      } else {
        return [{ type: 'EMPTY' }]
      }
    })
  )

export default mapEpic
