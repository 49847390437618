// @flow

import React from 'react'

import ResetPasswordContainer from '../containers/ResetPasswordContainer'

function ResetPasswordRoute () {
  return <ResetPasswordContainer />
}

export default ResetPasswordRoute
