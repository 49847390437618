// @flow

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  'cis-ice-charts': {
    type: 'fill',
    'source-layer': 'data'
    // filter: ['==', 'POLY_TYPE', 'I']
  },
  'floe-edge-polygons': {
    type: 'fill',
    'source-layer': 'floe-edge-polygons'
  },
  'floe-edge-linestrings': {
    type: 'line',
    'source-layer': 'floe-edge-linestrings'
  },
  'ice-drift-feature-tracking': {
    type: 'line',
    'source-layer': 'ice-drift-feature-tracking'
  },
  'floe-edge-coherence': {
    type: 'raster'
  },
  'floe-edge-convergence': {
    type: 'raster'
  },
  'sentinel-1': {
    type: 'raster'
  },
  'sentinel-2': {
    type: 'raster'
  },
  modis: {
    type: 'raster'
  },
  'sea-ice-fraction': {
    type: 'raster'
  },
  coastline: {
    type: 'raster'
  }
}

function setSource (state, action) {
  const { id, source } = action

  state[id] = source
}

const sourceDuck = createSlice({
  name: 'source',
  initialState,
  reducers: {
    setSource
  }
})

export default sourceDuck
