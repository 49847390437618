// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import loadable from '@loadable/component'

import NotAuthenticatedNavigationContainer from '../navigation/NotAuthenticatedNavigationContainer'
import RoutesContainer from '../navigation/RoutesContainer'
import { getAuth } from '../redux/authSelector'
const LoadingRoute = loadable(() => import('../routes/LoadingRoute'))

function NavigationContainer () {
  const auth = useSelector(state => getAuth(state))
  useFirestoreConnect([
    'river-ice-services',
    'river-ice-rivers',
    'river-ice-layers'
  ])

  return (
    <Router>
      {auth && !auth.isLoaded ? (
        <Route path='/' element={<LoadingRoute />} />
      ) : auth && !auth.isEmpty ? (
        <RoutesContainer />
      ) : (
        <NotAuthenticatedNavigationContainer />
      )}
    </Router>
  )
}

export default NavigationContainer
