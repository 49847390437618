// @flow

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

function MadeWithLove () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Made with ❤ by the '}
      <Link
        color='inherit'
        href='https://www.c-core.ca/'
        target='_blank'
        rel='noopener'
      >
        C-CORE
      </Link>
      {' team.'}
    </Typography>
  )
}

export default MadeWithLove
