// @flow

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'

import {
  getServiceRivers,
  getServiceLogoUri,
  getServiceLogoHref
} from '../redux/firestoreSelector'
import useNavigation from '../hooks/useNavigation'

const MapRoute = loadable(() => import('../routes/MapRoute'))

function RoutesContainer () {
  useNavigation()
  useSelector(getServiceRivers)
  const logoUri = useSelector(getServiceLogoUri)
  const logoHref = useSelector(getServiceLogoHref)

  return (
    <Routes>
      <Route
        path='/'
        element={<MapRoute logoUri={logoUri} logoHref={logoHref} />}
      />
      <Route
        path='signup'
        element={<MapRoute logoUri={logoUri} logoHref={logoHref} />}
      />
    </Routes>
  )
}

export default RoutesContainer
