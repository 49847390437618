// @flow

import dayjs from '../utils/dayjs'

const satellites = {
  'sentinel-1': {
    name: 'Sentinel-1',
    uri:
      'https://services.sentinel-hub.com/ogc/wms/6179cd9a-6ff5-43a5-8f01-8aef07e9f211?showLogo=false&service=WMTS&request=GetMap&layers=S1-DB-HH3&styles=&format=image%2Fpng&transparent=true&version=1.1.1&maxcc=100&height=512&width=512&srs=EPSG%3A3857&exceptions=BLANK&bbox={bbox-epsg-3857}'
  },
  'sentinel-2': {
    name: 'Sentinel-2',
    uri:
      'https://services.sentinel-hub.com/ogc/wms/318be53b-974b-4918-8f5f-53ba0a37079c?showLogo=false&service=WMTS&request=GetMap&layers=TRUE_COLOR&styles=&format=image%2Fpng&transparent=true&version=1.1.1&name=Sentinel-2&maxcc=60&height=512&width=512&srs=EPSG%3A3857&exceptions=BLANK&bbox={bbox-epsg-3857}'
  },
  modis: {
    name: 'modis',
    uri:
      'https://map1a.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_CorrectedReflectance_TrueColor/default/2020-09-21/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg'
  },
  'sea-ice-fraction': {
    name: 'Sea Ice Fraction',
    uri:
      'https://thredds.met.no/thredds/wms/sea_ice/SIW-DMI-ARC-SEAICE_HR-OBS/ice_conc_greenland_overview_aggregated?&service=WMS&request=GetMap&layers=ice_concentration&styles=boxfill%2Frainbow&format=image%2Fpng&transparent=true&version=1.1.1&test=0.9384988124610065&COLORSCALERANGE=0%2C100&NUMCOLORBANDS=20&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&LOGSCALE=false&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}'
  }
}

let template = {
  bbox: [-180, -90, 180, 90],
  assets: {
    raster_tiles: {
      href:
        'https://coresight-layers.s3-us-west-2.amazonaws.com/floe-edge-linestrings/2019-12-18T12:25:42.000Z--2019-12-19T12:18:19.000Z/{z}/{x}/{y}.pbf',
      title: 'Raster tiles'
    }
  },
  properties: {
    default_zoom: ['10'],
    maximum_zoom: 10,
    metadata_uri:
      'https://coresight-layers.s3-us-west-2.amazonaws.com/floe-edge-linestrings/2019-12-18T12:25:42.000Z--2019-12-19T12:18:19.000Z/metadata.json',
    minimum_zoom: 1,
    datetime: '2019-12-18T12:25:42Z',
    longitude: -81.035156,
    format: 'pbf',
    geojson_uri:
      'https://coresight-layers.s3-us-west-2.amazonaws.com/floe-edge-linestrings/2019-12-18T12:25:42.000Z--2019-12-19T12:18:19.000Z/geojson.geojson',
    tile_uri:
      'https://coresight-layers.s3-us-west-2.amazonaws.com/floe-edge-linestrings/2019-12-18T12:25:42.000Z--2019-12-19T12:18:19.000Z/{z}/{x}/{y}.pbf',
    latitude: 76.310296
  },
  links: [],
  type: 'Feature',
  collection: 'satellite',
  satellite: true,
  id:
    'floe-edge-linestrings-2019-12-18T12:25:42.000Z--2019-12-19T12:18:19.000Z',
  stac_version: '1.0.0-beta.2'
}

const dayInterval = 1000 * 60 * 60 * 24 // 1 day

function getDates (startDate, endDate, interval = dayInterval) {
  const duration = endDate - startDate
  const steps = duration / interval
  return Array.from({ length: steps + 1 }, (v, i) =>
    new Date(startDate.valueOf() + interval * i).toISOString()
  )
}

function createStacItem ({ collection, datetime }) {
  const datetimeFormatted = datetime.split('.')[0] + 'Z'
  const date = datetime.split('T')[0]
  const startDate = dayjs
    .utc(date)
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

  const name = satellites[collection].name
  const uri = satellites[collection].uri

  let item = JSON.parse(JSON.stringify(template))

  item.id = `${collection}-${date}`

  item.collection = collection
  if (item.properties) {
    item.properties['datetime'] = datetimeFormatted
  }

  if (collection === 'modis') {
    item.assets.raster_tiles.href = `https://map1a.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_CorrectedReflectance_TrueColor/default/${date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg`
  } else if (collection === 'sea-ice-fraction') {
    item.assets.raster_tiles.href = `${uri}&name=${name}`
  } else {
    item.assets.raster_tiles.href = `${uri}&name=${name}&time=${startDate}/${date}`
  }

  return item
}

function createStacItems ({
  collection = 'sentinel-1',
  delta = 206,
  today = new Date()
}) {
  let lastTwoWeeks = new Date()
  lastTwoWeeks.setDate(lastTwoWeeks.getDate() - delta)

  const datetimes = getDates(lastTwoWeeks, today)
    .slice()
    .reverse()

  const itemsArray = datetimes.map(datetime =>
    createStacItem({ collection, datetime })
  )

  const items = itemsArray.reduce((acc, item) => {
    const { id } = item
    acc[id] = item

    return acc
  }, {})

  return items
}

export default createStacItems
