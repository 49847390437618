// @flow

import { createSelector, createIdSelector } from 'redux-views'
import booleanIntersects from '@turf/boolean-intersects'
import bboxPolygon from '@turf/bbox-polygon'

import { getSelectedCatalogId } from '../redux/catalogSelector'
import dayjs from '../utils/dayjs'
import { serviceId, defaultRiverPreviews } from '../config'
import fitBounds from '../utils/fitBounds'
import createStacItems from '../utils/createStacItems'
import invertBbox from '../utils/invertBbox'

export const getPropRiverId = createIdSelector(({ riverId }) => riverId)

const getBounds = state => state.map.bounds

export const getFirestoreData = state => {
  const data = state.firestore.data
  window.data = data
  return data
}

export function returnDatetime (item) {
  // Given an item, return appropriate datetime
  // Depending on product, this might be ic:datetime_end, datetime_end, or datetime
  // TODO: protect against null item.properties
  if (!item || !item.properties) {
    return null
  }

  return (
    item.properties['ic:datetime_end'] ||
    item.properties['datetime_end'] ||
    item.properties['datetime']
  )
}

export const getData = createSelector([getFirestoreData], data => {
  const sentinel1Items = createStacItems({ collection: 'sentinel-1' })
  const sentinel2Items = createStacItems({ collection: 'sentinel-2' })
  const modisItems = createStacItems({ collection: 'modis' })
  const seaIceFractionItems = createStacItems({
    collection: 'sea-ice-fraction',
    delta: 0
  })
  const nextData = {
    ...data,
    'sentinel-1': sentinel1Items,
    'sentinel-2': sentinel2Items,
    modis: modisItems,
    'sea-ice-fraction': seaIceFractionItems
  }

  return nextData
})

export const getOrdered = state => state.firestore.ordered

export const getDatetime = state => state.datetime.datetime

export const getRivers = state =>
  state.firestore &&
  state.firestore.data &&
  state.firestore.data['river-ice-rivers']

export const getSelectedCatalog = createSelector(
  [getData, getSelectedCatalogId],
  (data, catalogId) => {
    const catalog = data && data[catalogId]
    // if (catalog) {
    //   console.log({ catalog })
    //   console.log(Object.keys(catalog).length)
    // }
    return catalog || {}
  }
)

export const getSelectedItemsInBounds = createSelector(
  [getSelectedCatalog, getBounds, getSelectedCatalogId],
  (catalog, bounds, catalogId) => {
    if (!catalog || bounds.length === 0 || isNaN(bounds[0])) {
      return []
    }
    // const boundingBox = bboxPolygon(bounds)

    const items = Object.values(catalog)

    // Hack to not filter satellite items
    if (items && items.length && items[0]['satellite']) {
      return items
    }

    // Hack to handle gdal bbox order instead of stac bbox order
    if (['floe-edge-convergence', 'floe-edge-coherence'].includes(catalogId)) {
      const filteredItems = items.filter(item => {
        if (
          item &&
          booleanIntersects(
            bboxPolygon(invertBbox({ bbox: item.bbox })),
            bboxPolygon(bounds)
          )
        ) {
          return true
        } else {
          return false
        }
      })

      return filteredItems
    }
    const filteredItems = items.filter(item => {
      if (
        item &&
        booleanIntersects(bboxPolygon(item.bbox), bboxPolygon(bounds))
      ) {
        return true
      } else {
        return false
      }
    })

    return filteredItems
  }
)

export const getIceThickness = state =>
  state.firestore.ordered['river-ice-thickness']

export const getIceThicknessGeoJson = createSelector(
  [getIceThickness, getDatetime],
  (iceThickness, datetime) => {
    if (!iceThickness) {
      return null
    }

    const recentData = iceThickness.slice(iceThickness.length - 4)

    const geojson = {
      type: 'FeatureCollection',
      features: recentData.map(datum => toGeoJson(datum))
    }

    return geojson
  }
)

function toGeoJson (data) {
  const geojson = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [data['geometry'].longitude, data['geometry'].latitude]
    },
    properties: {
      ...data,
      date: dayjs.utc(data['date'].seconds * 1000).format('YYYY-MM-DD')
    }
  }

  return geojson
}

export const getService = state =>
  state.firestore &&
  state.firestore.data &&
  state.firestore.data['river-ice-services'] &&
  state.firestore.data['river-ice-services'][serviceId]

export const getServiceRivers = createSelector([getService], service => {
  const serviceRivers = service && service.rivers

  return serviceRivers || []
})

export const getServiceLogoUri = createSelector([getService], service => {
  const serviceLogoUri = service && service.logoUri

  return serviceLogoUri
})

export const getServiceLogoHref = createSelector([getService], service => {
  const serviceLogoHref = service && service.logoHref

  return serviceLogoHref
})

export const getMapConfigs = createSelector(
  [getServiceRivers, getOrdered, getRivers],
  (serviceRivers, ordered, rivers) => {
    if (!serviceRivers || !rivers || !ordered) {
      return []
    }

    const mapConfigs = defaultRiverPreviews
      .filter(river => serviceRivers.includes(river.id))
      .map(river => {
        const catalogId = rivers[river.id]['catalogs'][0]
        const items = ordered[catalogId]
        if (!items) {
          return river
        } else {
          const item = items[items.length - 1]
          const url =
            item && item.assets ? item.assets['vector_tiles'].href : river.url
          const viewport =
            item && item.bbox ? fitBounds({ bounds: item.bbox }) : {}
          return { ...river, url, viewport }
        }
      })

    return mapConfigs || []
  }
)

export const getServiceTitle = createSelector([getService], service => {
  if (!service) {
    return ''
  }

  const { title } = service

  return title || ''
})

export const getRiverFromId = createSelector(
  [getRivers, getPropRiverId, getServiceRivers],
  (rivers, riverId, serviceRivers) => {
    if (!rivers) {
      return {}
    }
    // Handle special case of only one river skipping the
    // RiverSelector route
    if (!riverId && serviceRivers && serviceRivers.length === 1) {
      return rivers[serviceRivers[0]] || {}
    }
    // TODO: Fix hard coded value. 'Signup' route was causing application
    // to fetch wrong configuration
    // const river = rivers && rivers['polar-view']
    const river = rivers && rivers[riverId]

    return river || {}
  }
)

export const getCatalogsFromId = createSelector(
  [getRiverFromId, getPropRiverId],
  (river, riverId) => {
    if (!river) {
      return []
    }

    const { catalogs } = river

    return catalogs
  }
)

export const getFirestoreConnect = createSelector(
  [getCatalogsFromId, getSelectedCatalogId],
  (catalogs, selectedCatalogId) => {
    if (!catalogs) {
      return 'floe-edge-polygons'
    }
    // TODO: Remove temporary ice drift limit
    // 2020-07-28T12:00:12.769Z
    if (selectedCatalogId === 'ice-drift-feature-tracking') {
      const connect = [
        // {
        //   collection: selectedCatalogId,
        //   orderBy: ['id', 'desc'],
        //   startAt: 'ice-drift-feature-tracking-2021-04-15T12:57:34.475Z--2020-07-28T12:00:12.769Z',
        //   limit: 2000
        // },
        {
          collection: selectedCatalogId,
          orderBy: ['id', 'desc'],
          // startAt: 'ice-drift-feature-tracking-2021-04-15T12:57:34.475Z--2020-07-28T12:00:12.769Z',
          limit: 2000
        }
      ]
      // console.log({ connect })

      return connect
    }
    const firestoreConnect = catalogs
      .filter(catalog => catalog === selectedCatalogId)
      .map(catalog => {
        return { collection: catalog, orderBy: ['id', 'desc'] }
      })
    // console.log({ firestoreConnect })
    return firestoreConnect
  }
)

export const getCatalogsFromServiceId = createSelector(
  [getServiceRivers, getRivers],
  (serviceRivers, rivers) => {
    if (!serviceRivers || !rivers) {
      return []
    }
    const catalogs = serviceRivers.reduce((acc, serviceRiver) => {
      const river = rivers[serviceRiver]
      const { catalogs } = river

      return [...acc, ...catalogs]
    }, [])

    return catalogs
  }
)

export const getLayerName = createSelector(
  [getData, getSelectedCatalogId],
  (data, catalogId) => {
    const layers = data && data['river-ice-layers']
    if (!layers) {
      return ''
    }
    const layer = layers[catalogId]
    const { title } = layer

    return title
  }
)

export const getAvailableLayers = createSelector(
  [getData, getSelectedCatalogId, getRiverFromId, getPropRiverId],
  (data, catalogId, river, riverId) => {
    const { catalogs } = river

    const layers = data && data['river-ice-layers']

    if (layers && catalogs && catalogs.length > 0) {
      const availableLayers = catalogs
        .map(id => layers[id])
        .filter(layer => layer)
        .filter(layer => layer.type === 'vector' || layer.type === 'raster')

      return availableLayers
    } else {
      return []
    }
  }
)

export const getRenderHomeButton = createSelector(
  [getServiceRivers],
  rivers => {
    const renderHomeButton = rivers && rivers.length > 1

    return renderHomeButton
  }
)

export const getDatetimes = createSelector(
  [getSelectedItemsInBounds],
  items => {
    const datetimes = items
      .filter(item => item && item.properties)
      // Convert python iso string to javascript iso string
      // Use datetime_end if present, otherwise fallback to datetime
      .map(
        item =>
          dayjs.utc(returnDatetime(item)).format('YYYY-MM-DD') + 'T00:00:00.00Z'
      )
    const sortedDatetimes = datetimes.slice().sort((a, b) => (a < b ? 1 : -1))
    const uniqueDatetimes = [...new Set(sortedDatetimes)]

    return uniqueDatetimes
  }
)

export const getDatetimeOrDefault = createSelector(
  [getDatetime, getDatetimes],
  (datetime, datetimes) => {
    if (!datetime) {
      return datetimes[0]
    }

    const formattedDatetime = `${datetime.split('Z')[0]}.00Z`
    const selectedDatetime = datetimes.includes(formattedDatetime)
      ? datetime
      : datetimes[0]

    return selectedDatetime
  }
)

export const getSelectedItem = createSelector(
  [getSelectedCatalogId, getDatetimeOrDefault, getData],
  (catalogId, datetime, data) => {
    const catalog = data && data[catalogId]

    const item =
      catalog &&
      (Object.values(catalog).find(
        item => item && item.properties && returnDatetime(item) === datetime
      ) ||
        Object.values(catalog)[Object.values(catalog).length - 1])

    return item
  }
)

export const getItems = createSelector([getSelectedItemsInBounds], items => {
  if (items) {
    const sortedItems = items
      .slice()
      .sort((a, b) => (returnDatetime(a) < returnDatetime(b) ? -1 : 1))

    return sortedItems
  } else {
    return []
  }
})

function filterSameDate (datetime, item) {
  if (!item || !item.properties || !returnDatetime(item) || !datetime) {
    return false
  }

  const date = datetime.split('T')[0]
  const itemDate = returnDatetime(item).split('T')[0]
  const filter = date === itemDate

  return filter
}

export const getSelectedItems = createSelector(
  [getSelectedCatalogId, getDatetimeOrDefault, getData],
  (catalogId, datetime, data) => {
    const catalog = data && data[catalogId]

    const items =
      catalog &&
      (Object.values(catalog).filter(item => filterSameDate(datetime, item)) ||
        Object.values(catalog)[Object.values(catalog).length - 1])

    if (items) {
      return items
    } else {
      return []
    }
  }
)
