// @flow

// import './wdyr'

import "core-js/stable"
import "regenerator-runtime/runtime"
import 'whatwg-fetch'

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'

import { sentryDsn } from './config'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: sentryDsn})

  LogRocket.init('3ujpkv/river-ice-web')
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
