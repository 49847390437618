// @flow

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  catalogs: [
    'river-ice-classification',
    'river-ice-change',
    'river-ice-thickness'
  ],
  selected: 'river-ice-classification'
}

function setSelected (state, action) {
  const { payload } = action

  state.selected = payload
}

const catalogDuck = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setSelected
  }
})

export default catalogDuck
