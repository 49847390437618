// @flow

import { createSlice } from '@reduxjs/toolkit'

import { classificationLegend, changeLegend, iceChartLegend } from '../config'

// const iceChartFill = [
//   'step',
//   ['to-number', ['get', 'CT']],
//   ...['black', 0, 'rgba(255, 255, 255, 0)'].concat(
//     ...iceChartLegend.map(item => [item.value, item.color])
//   )
// ]
const iceChartFill = [
  'step',
  ['to-number', ['case', ['has', 'CT'], ['get', 'CT'], '-1']],
  ...['rgba(255, 255, 255, 0.1)'].concat(
    ...iceChartLegend.map(item => [item.value, item.color])
  )
]

const initialState = {
  classification: {
    'fill-opacity': 1,
    'fill-color': [
      'interpolate',
      ['linear'],
      ['to-number', ['get', 'gridcode']],
      ...[].concat(
        ...classificationLegend.map(item => [item.value, item.color])
      )
    ],
    'fill-outline-color': 'rgba(0,0,0,0)'
  },
  change: {
    'fill-opacity': 1,
    'fill-color': [
      'interpolate',
      ['linear'],
      ['to-number', ['get', 'change']],
      ...[].concat(...changeLegend.map(item => [item.value, item.color]))
    ],
    'fill-outline-color': 'rgba(0,0,0,0)'
  },
  cisIceCharts: {
    'fill-opacity': [
      'case',
      ['==', ['get', 'POLY_TYPE'], 'W'],
      0.5,
      ['case', ['<=', ['to-number', ['get', 'CT']], 10], 0.75, 1]
    ],
    'fill-color': iceChartFill,
    // 'fill-color': [
    //   'interpolate',
    //   ['linear'],
    //   ['to-number', ['get', 'CT']],
    //   ...[].concat(...iceChartLegend.map(item => [item.value, item.color]))
    // ],
    'fill-outline-color': 'rgba(0,0,0,1)'
  },
  floeEdgePolygons: {
    'fill-color': 'blue'
  },
  floeEdgeLinestrings: {
    'line-color': 'blue',
    'line-width': 5
  },
  iceDriftFeatureTracking: {
    'line-color': [
      'interpolate',
      ['linear'],
      ['number', ['get', 'speed_ms']],
      0,
      '#000000',
      0.05,
      '#a429b9',
      0.1,
      '#1d1df7',
      0.15,
      '#21e721',
      0.2,
      '#ff7e00',
      0.25,
      '#d61316'
    ],
    // 'line-width': 2,
    'line-width': ['interpolate', ['exponential', 2], ['zoom'], 0, 3, 18, 500]
  }
}

function setPaint (state, action) {
  const { id, paint } = action

  state[id] = paint
}

const paintDuck = createSlice({
  name: 'paint',
  initialState,
  reducers: {
    setPaint
  }
})

export default paintDuck
