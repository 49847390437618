// @flow

import { createSlice } from '@reduxjs/toolkit'

import dayjs from '../utils/dayjs'
import { sensors } from '../config'

function setDatetime (state, action) {
  const { datetime } = action.payload
  state.datetime = datetime

  return state
}

function addDay (state, action) {
  const { currentDatetime } = action.payload
  const nextDatetime = currentDatetime
    ? dayjs(currentDatetime)
        .utc()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    : dayjs(state.datetime)
        .utc()
        .add(1, 'day')
        .format('YYYY-MM-DD')
  state.datetime = nextDatetime

  return state
}

function subtractDay (state, action) {
  const { currentDatetime } = action.payload
  const nextDatetime = currentDatetime
    ? dayjs(currentDatetime)
        .utc()
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    : dayjs(state.datetime)
        .utc()
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
  state.datetime = nextDatetime

  return state
}

function selectSatellite (state, action) {
  const { id } = action.payload
  state.selectedId = id

  return state
}

const satellite = createSlice({
  name: 'satellite',
  initialState: {
    datetime: '2019-09-10',
    timestamp: null,
    selectedId: 'none',
    sensors
  },
  reducers: {
    addDay,
    setDatetime,
    subtractDay,
    selectSatellite
  }
})

export default satellite
