// @flow

import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

import downloadDuck from '../redux/downloadDuck'
import { getSelectedItem } from '../redux/firestoreSelector'

const downloadEpic = (action$, state$) =>
  action$.pipe(
    ofType(downloadDuck.actions.downloadRequest),
    map(action => {
      const state = state$.value
      const item = getSelectedItem(state)

      try {
        const url = item.assets.shapefile.href
        window.open(url)
      } catch (error) {
        console.warn(error)
      }

      return downloadDuck.actions.downloadSuccess()
    })
  )

export default downloadEpic
