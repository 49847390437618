// @flow

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  datetime: undefined
}

function setDatetime (state, action) {
  const { payload } = action

  if (payload && payload.length === 23) {
    // TODO find out where datetime strings of length 23 are leaking in
    state.datetime = payload.replace('.00Z', 'Z')
  } else {
    state.datetime = payload
  }
}

const datetimeDuck = createSlice({
  name: 'datetime',
  initialState,
  reducers: {
    setDatetime
  }
})

export default datetimeDuck
