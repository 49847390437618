import { createSlice } from '@reduxjs/toolkit'

function changeHover (state, action) {
  const { payload } = action

  return { ...payload }
}

const hover = createSlice({
  name: 'hover',
  initialState: {
    properties: null,
    x: null,
    y: null,
    lng: null,
    lat: null
  },
  reducers: {
    changeHover
  }
})

export default hover
