// @flow

import React from 'react'

import SignInContainer from '../containers/SignInContainer'

function SignInRoute () {
  return <SignInContainer />
}

export default SignInRoute
