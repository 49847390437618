import { createSlice } from '@reduxjs/toolkit'

import { defaultParameters } from '../config'

function setSettings (state, action) {
  const { payload } = action

  return { ...state, ...payload }
}

function toggleDialogOpen (state) {
  const { dialogOpen } = state

  return { ...state, dialogOpen: !dialogOpen }
}

function toggleChartOpen (state) {
  const { chartOpen } = state

  return { ...state, chartOpen: !chartOpen }
}

const auth = createSlice({
  name: 'settings',
  initialState: {
    ...defaultParameters,
    dialogOpen: false
  },
  reducers: {
    setSettings,
    toggleDialogOpen,
    toggleChartOpen
  }
})

export default auth
