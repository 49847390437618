// @flow
import geoViewport from '@mapbox/geo-viewport'

function fitBounds ({
  bounds,
  divisor = 3
}: {
  bounds: Number[],
  divisor?: Number
}) {
  const width = window.innerWidth / divisor
  const height = window.innerHeight / divisor

  // console.log(geoViewport.viewport(bounds, [width, height]))
  const {
    center: [longitude, latitude],
    zoom
  } = geoViewport.viewport(bounds, [width, height])
  // console.log('fitBounds')
  // console.log(bounds, width, height)
  // console.log(longitude, latitude, zoom)
  return { longitude, latitude, zoom }
}

export default fitBounds
