// @flow

import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Link as RouterLink } from 'react-router-dom'

import SignUp from '../components/SignUp'

function SignUpContainer () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)
  const firebase = useFirebase()

  function handleSignUp () {
    if (email === '') {
      setError('Enter your email')
    } else if (password === '') {
      setError('Enter your password')
    } else {
      setError(null)
      setFetching(true)
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => setFetching(true))
        .then(() => window.history.pushState({}, null, '/'))
        .catch(error => {
          setFetching(false)
          setError(error.message)
        })
    }
  }

  return (
    <SignUp
      handleSignUp={handleSignUp}
      email={email}
      password={password}
      error={error}
      fetching={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
      RouterLink={RouterLink}
    />
  )
}

export default SignUpContainer
