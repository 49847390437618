export const getAuth = state => state.firebase.auth

export const getUid = state => state.firebase.auth?.uid

export const email = state => state.firebase.auth?.email

export const getUploadAuth = state => {
  const email = state.firebase.auth?.email
  if (!email) {
    return false
  } else {
    const emailSubStrings = ['@c-core.ca', 'jthetzel@gmail.com', 'bennett']
    const auth = emailSubStrings.some(s => email.includes(s))
    return auth
  }
}
