// @flow

import reactGA from 'react-ga'

const production = process.env.NODE_ENV === 'production'

const defaultConfiguration = [
  {
    trackingId: 'UA-154399427-1',
    gaOptions: {}
  },
  {
    trackingId: 'UA-73000050-1',
    gaOptions: {}
  }
]

const defaultOptions = {
  debug: !production,
  testMode: !production,
  siteSpeedSampleRate: 100
}

reactGA.initialize(defaultConfiguration, defaultOptions)

export default reactGA
