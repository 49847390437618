import { createSlice } from '@reduxjs/toolkit'

function downloadRequest (state, action) {
  return state
}

function downloadSuccess (state, action) {
  return state
}

const download = createSlice({
  name: 'download',
  initialState: {},
  reducers: {
    downloadRequest,
    downloadSuccess
  }
})

export default download
