// @flow

import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'

import MadeWithLove from '../components/MadeWithLove'
import SponsorLogo from '../components/SponsorLogo'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
}))

function ResetPassword ({
  handleResetPassword = console.log,
  RouterLink,
  email,
  setEmail = console.log,
  error,
  fetching
}: {
  handleResetPassword?: Function,
  RouterLink?: mixed,
  email?: string,
  error?: string,
  fetching?: boolean,
  setEmail?: Function
}) {
  const classes = useStyles()

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <div className={classes.wrapper}>
          <SponsorLogo
          //src='/images/polar-view-logo.png'
          />
        </div>
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {fetching && (
            <CircularProgress size={55} className={classes.fabProgress} />
          )}
        </div>
        <Typography component='h1' variant='h5'>
          Reset password
        </Typography>
        <Typography component='h1' variant='subtitle1'>
          {error}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            // autoComplete="email"
            autoFocus
            onChange={event => setEmail(event.target.value)}
            value={email}
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={handleResetPassword}
          >
            Send Password Reset Email
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to='/' variant='body2' component={RouterLink}>
                {'Remeber your password? Sign In'}
              </Link>
            </Grid>
            <Grid item xs>
              <Link to='/signup' variant='body2' component={RouterLink}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
  )
}

export default ResetPassword
