// @flow

import React from 'react'
import { Routes, Route } from 'react-router-dom'

import SignInRoute from '../routes/SignInRoute'
import SignUpRoute from '../routes/SignUpRoute'
import ResetPasswordRoute from '../routes/ResetPasswordRoute'

function NotAuthenticatedNavigationContainer () {
  return (
    <Routes>
      <Route path='/' element={<SignInRoute />} />
      <Route path='/signup' element={<SignUpRoute />} />
      <Route path='/resetpassword' element={<ResetPasswordRoute />} />
    </Routes>
  )
}

export default NotAuthenticatedNavigationContainer
