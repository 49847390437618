// service id
export const serviceId = process.env.REACT_APP_SERVICE_ID
// export const serviceId = 'cis-ice-charts'
if (!serviceId) {
  console.error('Service ID not found in environment variable. Please set `REACT_APP_SERVICE_ID` to a service ID (e.g. "churchill", or "new-brunswick").')
}

// Testing
export const testVectorUrl = 'https://river-ice.s3.amazonaws.com/churchill/tiles/2018-11-22_CHU_CHG_R2/{z}/{x}/{y}.pbf'

// Firebase
export const apiKey = process.env.REACT_APP_API_KEY
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN
export const databaseURL = process.env.REACT_APP_DATABASE_URL
export const projectId = process.env.REACT_APP_PROJECT_ID
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
export const appId = process.env.REACT_APP_APP_ID
export const firebaseConfig = {
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId
}


// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

// Sentry
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN

// Uppy
export const awsEndpoint = process.env.REACT_APP_COMPANION_AWS_ENDPOINT
export const awsBucket = process.env.REACT_APP_COMPANION_AWS_BUCKET
export const awsKey = process.env.REACT_APP_COMPANION_AWS_KEY
export const awsSecret = process.env.REACT_APP_COMPANION_AWS_SECRET
process.env.COMPANION_AWS_ENDPOINT = process.env.REACT_APP_COMPANION_AWS_ENDPOINT
process.env.COMPANION_AWS_BUCKET = process.env.REACT_APP_COMPANION_AWS_BUCKET
process.env.COMPANION_AWS_KEY = process.env.REACT_APP_COMPANION_AWS_KEY
process.env.COMPANION_AWS_SECRET = process.env.REACT_APP_COMPANION_AWS_SECRET
export const preSignedUrl = 'https://rpas-cog-api-zt6djfhudq-ue.a.run.app/v2/upload-url'
export const processCog = 'https://rpas-cog-api-zt6djfhudq-ue.a.run.app/v2/process-floe-edge'
export const titilerUrl = 'https://titiler-cbwtxvwtwq-ue.a.run.app'


// Default map values
export const defaultViewport = {
  latitude: 70,
  longitude: -64,
  zoom: 3.5
}

// Mosaic map values
export const mosaicViewport = {
  // latitude: 56.402694296828265,
  latitude: 56.4019238037625,
  // longitude: -116.87770843515855,
  longitude: -116.88076141998108,
  zoom: 20,
}


// Legend values
export const iceChartLegend = [
  // { value: 0, color: 'black', label: '0% to 10%', title: 'Easy navigation', strokeWidth: 10 },
  { value: 0, color: '#ADD8E6', label: '0% to 10%', title: 'Easy navigation', strokeWidth: 10 },
  { value: 10, color: '#008000', label: '10% to 30%', title: 'Easy navigation', strokeWidth: 10 },
  { value: 40, color: '#FFFF00', label: '40% to 60%', title: 'Difficult navigation', strokeWidth: 10 },
  { value: 70, color: '#FFA500', label: '70% to 80%', title: 'Difficult navigation', strokeWidth: 10 },
  { value: 90, color: '#FF0000', label: '90% to 100%', title: 'Severe navigation', strokeWidth: 10 }
]
export const classificationLegend = [
  { value: 31, color: '#004D95', label: 'Open water', title: 'Open water', strokeWidth: 10 },
  { value: 32, color: '#60C0F3', label: 'Wet ice or pooling water on ice', title: 'Wet ice or pooling water on ice', strokeWidth: 10 },
  { value: 33, color: '#FDFA00', label: 'Smooth ice', title: 'Smooth ice', strokeWidth: 10 },
  { value: 34, color: '#EB3D00', label: 'Rough ice', title: 'Rough ice', strokeWidth: 10 }
]

export const changeLegend = [
  { value: -2, color: '#0001FC', label: 'Much less ice', title: 'Much less ice' },
  { value: -1, color: '#31CCFB', label: 'Less ice', title: 'Less ice' },
  { value: 0, color: '#009B01', label: 'No change', title: 'No change' },
  { value: 1, color: '#FDFE00', label: 'More ice', title: 'More ice' },
  { value: 2, color: '#FE0002', label: 'Much more ice', title: 'Much more ice' }
]

// River map configurations
export const classificationPaint = {
  'fill-opacity': 1,
  'fill-color': [
    'interpolate',
    ['linear'],
    ['to-number', ['get', 'gridcode']],
    ...[].concat(...classificationLegend.map(item => [item.value, item.color]))
  ],
  'fill-outline-color': 'rgba(0,0,0,0)'
}

export const defaultRiverPreviews = [
  {
    name: 'Churchill',
    id: 'churchill',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2020-04-09_CHU_CLS/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Miramichi',
    id: 'miramichi',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2020-03-02_CHU/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Nashwaak',
    id: 'nashwaak',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2020-01-02_CHU/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Saint-Jean',
    id: 'saint-jean',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2019-05-30_CHU/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Tobique',
    id: 'tobique',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2019-04-25_CHU/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Ristigouche',
    id: 'ristigouche',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2018-12-26_CHU/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  },
  {
    name: 'Madawaska',
    id: 'madawaska',
    url:
      'https://river-ice.s3.amazonaws.com/churchill/tiles/2020-05-03_CHU_CLS_R2/{z}/{x}/{y}.pbf',
    paint: classificationPaint
  }
]

// Mosaic urls
const debug = false
export const defaultMosaicUrl =
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi1_mosaic.json'
export const defaultExtension = 'png'
export const defaultScale = 1
export const defaultPixelSelection = 'first'
export const defaultResamplingMethod = 'nearest'

export const defaultTilerUrl = !debug
  ? 'https://mosaic-tiler.c-core.app/secure/mosaic/{z}/{x}/{y}'
  : 'http://localhost:8080/secure/mosaic/{z}/{x}/{y}'
export const defaultTileJsonUrl = !debug
  ? 'https://mosaic-tiler.c-core.app/secure/mosaic/tilejson.json'
  : 'http://localhost:8080/secure/mosaic/tilejson.json'

export const defaultScales = [1, 2, 3]

export const defaultRescale = '0,255-0,255-0,255'
export const defaultRescales = [defaultRescale]

export const defaultTilerUrls = [defaultTilerUrl]

export const defaultTileJsonUrls = [defaultTileJsonUrl]

export const defaultMosaicUrls = [
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi1_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi2_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi3_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi4_mosaic.json'
]

export const defaultExtensions = [
  'png',
  'jpg',
]

export const pixelSelectionMethods = {
  first: 'first',
  highest: 'highest',
  lowest: 'lowest',
  mean: 'mean',
  median: 'median',
  stdev: 'stdev',
  bdix_stdev: 'bdix_stdev'
}

export const defaultPixelSelections = Object.values(pixelSelectionMethods)

export const resamplingMethods = {
  nearest: 'nearest',
  bilinear: 'bilinear',
  cubic: 'cubic',
  cubic_spline: 'cubic_spline',
  lanczos: 'lanczos',
  mode: 'mode',
  gauss: 'gauss',
  max: 'max',
  min: 'min',
  med: 'med',
  q1: 'q1',
  q3: 'q3'
}

export const defaultResamplingMethods = Object.values(resamplingMethods)

export const defaultIndexes = '1, 2, 3'

export const defaultIndexesArray = [defaultIndexes]

export const defaultSensorIds = [
  { value: 'none', label: 'None' },
  { value: 'sentinel-1', label: 'Sentinel 1'},
  { value: 'sentinel-2', label: 'Sentinel 2'},
  { value: 'modis', label: 'Modis'},
  // { value: 'sea-ice-fraction', label: 'CMEMS Sea Ice Fraction'},
]

// export const defaultSensorId = { value: 'none', label: 'None' }
export const defaultSensorId = { value: 'none', label: 'None' }

export const defaultDisplayBounds = false

export const defaultParameters = {
  mosaicUrl: defaultMosaicUrl,
  extension: defaultExtension,
  scale: defaultScale,
  pixelSelection: defaultPixelSelection,
  resamplingMethod: defaultResamplingMethod,
  indexes: defaultIndexes,
  rescale: defaultRescale,
  tilerUrl: defaultTilerUrl,
  tileJsonUrl: defaultTileJsonUrl,
  sensorId: defaultSensorId,
  displayBounds: defaultDisplayBounds,
  chartOpen: false,
}

// Satellite sensors
export const sensors = {
  'sentinel-1': {
    id: 'sentinel-1',
    label: 'Sentinel 1',
    type: 'wmts',
    url: ({ date = '2019-09-01' }) =>
    `https://services.sentinel-hub.com/ogc/wms/6179cd9a-6ff5-43a5-8f01-8aef07e9f211?showLogo=false&service=WMTS&request=GetMap&layers=S1-DB-HH3&styles=&format=image%2Fpng&transparent=true&version=1.1.1&name=Sentinel-1&maxcc=100&height=512&width=512&srs=EPSG%3A3857&exceptions=BLANK&bbox={bbox-epsg-3857}&time=${date}/${date}`
  },
  'sentinel-2': {
    id: 'sentinel-2',
    label: 'Sentinel 2',
    type: 'wmts',
    url: ({ date = '2019-09-01' }) =>
    `https://services.sentinel-hub.com/ogc/wms/318be53b-974b-4918-8f5f-53ba0a37079c?showLogo=false&service=WMTS&request=GetMap&layers=TRUE_COLOR&styles=&format=image%2Fpng&transparent=true&version=1.1.1&name=Sentinel-2&maxcc=60&height=512&width=512&srs=EPSG%3A3857&exceptions=BLANK&bbox={bbox-epsg-3857}&time=${date}/${date}`
  },
  modis: {
    id: 'modis',
    label: 'Modis',
    type: 'xyz',
    url: ({ date = '2019-09-01' }) =>
    `https://map1a.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_CorrectedReflectance_TrueColor/default/${date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg`
  },
  // 'sea-ice-fraction': {
  //   id: 'sea-ice-fraction',
  //   label: 'CMEMS Sea Ice Fraction',
  //   type: 'wmts',
  //   url: ({ date = '2019-09-01' }) =>
  //   `https://thredds.met.no/thredds/wms/sea_ice/SIW-DMI-ARC-SEAICE_HR-OBS/ice_conc_greenland_overview_aggregated?&service=WMS&request=GetMap&layers=ice_concentration&styles=boxfill%2Frainbow&format=image%2Fpng&transparent=true&version=1.1.1&test=0.9384988124610065&COLORSCALERANGE=0%2C100&NUMCOLORBANDS=20&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&LOGSCALE=false&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}`
  // },
}
