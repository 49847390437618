// @flow

import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

import catalogDuck from '../redux/catalogDuck'
import mapDuck from '../redux/mapDuck'
import { getRiverFromId } from '../redux/firestoreSelector'

const catalogEpic = (action$, state$) =>
  action$.pipe(
    ofType(mapDuck.actions.changeRiver),
    map(action => {
      const { payload } = action
      const state = state$.value
      const riverId = payload.slice(1)
      const river = getRiverFromId(state, { riverId })
      const { catalogs } = river

      if (catalogs && catalogs.length) {
        const catalog = catalogs[0]

        return catalogDuck.actions.setSelected(catalog)
      } else {
        return { type: 'EMPTY' }
      }
    })
  )

export default catalogEpic
