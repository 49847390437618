// @flow

import React from 'react'

import SignUpContainer from '../containers/SignUpContainer'

function SignUpRoute () {
  return <SignUpContainer />
}

export default SignUpRoute
